import styled from "styled-components"

import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  margin: 40px auto;
`
export const WrapperHeader = styled.div`
  display: block;
  padding-bottom: 20px;

  @media ${device.desktop} {
    padding-bottom: 60px;
  }
  .bg-image {
    max-height: 700px;
    min-height: 240px;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: bottom;
    margin-bottom: 0;

    @media ${device.tablet} {
      min-height: 500px;
    }
  }
`
export const Header = styled.div`
  max-width: 1200px;
  max-height: 700px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 3;
  align-items: center;
  padding-top: 45px;
  pointer-events: none;

  svg {
    width: 16px;
    margin-right: 14px;

    @media ${device.tablet} {
      width: 23px;
    }

    :hover {
      g {
        opacity: 1;
      }

      transition: 70ms;
    }
  }

  @media ${device.tablet} {
    padding-top: 107px;
  }
  @media ${device.desktop} {
    padding-top: 127px;
  }
  @media ${device.large} {
    padding-top: 145px;
  }
`
export const Title = styled.h1`
  font-size: 35px;
  color: #ffffff;
  letter-spacing: -1.09px;
  text-align: center;
  line-height: 42px;
  margin-bottom: 10px;

  @media (min-width: 366px) {
    font-size: 42px;
  }

  @media ${device.tablet} {
    font-size: 72px;
    letter-spacing: -1.88px;
    text-align: center;
    line-height: 76px;
    margin-bottom: 50px;
  }
  @media ${device.large} {
    font-size: 96px;
    letter-spacing: -2.5px;
    line-height: 80px;
    margin-bottom: 58px;
  }
`
export const Search = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #fff;
  margin-bottom: 75px;
  /* width: 275px; */

  @media ${device.tablet} {
    padding-bottom: 25px;
    margin-bottom: 160px;
    width: 460px;
  }
  @media ${device.desktop} {
    margin-bottom: 205px;
  }
  @media ${device.large} {
    margin-bottom: 190px;
    width: 574px;
  }
`

export const CloseSearch = styled.button`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: ${props => (props.queryExists ? 1 : 0)};
  transition: 100ms;
  pointer-events: ${props => (props.queryExists ? "all" : "none")};
  svg.search-remove {
    margin: 0;
    width: 18px;
    display: block;

    @media ${device.tablet} {
      width: 25px;
    }
  }
`
export const Input = styled.input`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  outline: none;
  width: 100%;
  letter-spacing: 0;
  line-height: 18px;
  background-color: transparent;
  border: none;
  pointer-events: all;

  ::placeholder {
    opacity: 1;
  }

  @media ${device.tablet} {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
  }
  @media ${device.large} {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px;
  }
`
export const ArtworkAuthor = styled.div`
  opacity: 0.5;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0;
  text-align: right;
  line-height: 24px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -35px;
  padding-right: 20px;
  position: relative;
  pointer-events: all;
  /* z-index: 100; */

  @media ${device.tablet} {
    font-size: 12px;
    color: #4a5050;
    line-height: 24px;
    padding-right: 40px;
    margin-top: -60px;
  }

  a {
    color: #4a5050;
    cursor: pointer;
  }
`

export const ResultsWrapper = styled.div`
  position: absolute;
  bottom: 78px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: calc(100% - 20px);
  background: white;
  z-index: 40;
  border-radius: 2px;
  opacity: ${props => (props.queryExists ? 1 : 0)};
  pointer-events: ${props => (props.queryExists ? "all" : "none")};
  box-shadow: 10px 15px 35px 0 rgba(0, 0, 0, 0.35);
  padding: 30px;

  img {
    min-height: auto;
  }

  @media ${device.tablet} {
    padding: 38px;
    width: calc(100% - 46px);
    bottom: 163px;
  }

  @media ${device.desktop} {
    width: calc(100% - 120px);
    bottom: 207px;
  }

  @media ${device.large} {
    width: 100%;
    padding: 60px;
    padding-top: 50px;
    bottom: 192px;
  }
`
