import { useStaticQuery, graphql } from 'gatsby';

export const GraphicDesign = () => {
	const { wpgraphql } = useStaticQuery(
		graphql`
			query graphicDesignPosts {
				wpgraphql {
					posts(first: 12, where: { categoryName: "design" }) {
						nodes {
							title
							colourFeatureImage
							link
							featuredImage {
								sourceUrl
								srcSet
								mediaDetails {
									width
									height
								}
							}
						}
					}
				}
			}
		`
	);

	return wpgraphql.posts.nodes;
};
