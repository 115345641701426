import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Products } from "../components/Products"
import { FromTheCommunity } from "../components/FromTheCommunity"
import Latest from "../components/Latest/index"
import { BrowseCategories } from "../components/Categories/index"
import { HomeGrid } from "../components/TeaserGrids"
import { AdvertisementWrapper } from "../components/AdvertisementWrapper"
import { GoogleAd } from "../components/GoogleAd"

import { Carousel } from "../components/Carousel"
import { GraphicDesign } from "../hooks/design-carousel"
import { Art } from "../hooks/art-carousel"
import { Photography } from "../hooks/photography-carousel"
import { Architecture } from "../hooks/architecture-carousel"
import { PopCulture } from "../hooks/pop-culture-carousel"
import { Fashion } from "../hooks/fashion-carousel"
import { Illustration } from "../hooks/illustration-carousel"
import { IndustrialDesign } from "../hooks/industrial-design-carousel"
import { EditorialHome } from "../components/EditorialHome"
import { PinnedEditorialData } from "../hooks/pinned-editorial-data"
import NewsletterPopup from "../components/NewsletterPopup/NewsletterPopup"

const HomePage = () => {
  const carousel1 = GraphicDesign()
  const carousel2 = Art()
  const carousel3 = Photography()
  const carousel4 = Architecture()
  const carousel5 = PopCulture()
  const carousel6 = Fashion()
  const carousel7 = Illustration()
  const carousel8 = IndustrialDesign()
  const pinnedEditorial = PinnedEditorialData()

  return (
    <Layout home adBelow pinnedEditorial={pinnedEditorial}>
      <SEO
        home
        title="Daily design inspiration for creatives"
        description="Inspiration Grid celebrates creative talent from around the world. Your daily fix of design, art, illustration, typography, photography, architecture, fashion and more."
        url={process.env.GATSBY_WEBSITE_DOMAIN}
      />

      <AdvertisementWrapper horizontal>
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["1485528790", "1390314586", "6875764920"]}
        />
      </AdvertisementWrapper>
      <Latest pinnedEditorial={pinnedEditorial} />

      <HomeGrid pinnedEditorial={pinnedEditorial} />
      <BrowseCategories title="Browse Categories" browser />
      <AdvertisementWrapper horizontal>
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["5233202118", "7319274598", "3549767581"]}
        />
      </AdvertisementWrapper>
      <EditorialHome />
      <Products title="Products we love" />

      <FromTheCommunity />

      <NewsletterPopup />

      <AdvertisementWrapper horizontal>
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["7048351827", "4212112565", "8712440832"]}
        />
      </AdvertisementWrapper>
      <Carousel
        cardsToShow={4}
        title="graphic design"
        posts={carousel1}
        ctaPath="/category/design/"
      />
      <Carousel
        cardsToShow={4}
        title="art"
        end
        posts={carousel2}
        ctaPath="/category/art/"
      />
      <Carousel
        cardsToShow={4}
        title="photography"
        posts={carousel3}
        ctaPath="/category/photography/"
      />
      <Carousel
        cardsToShow={4}
        title="architecture"
        end
        posts={carousel4}
        ctaPath="/category/architecture/"
      />
      <Carousel
        cardsToShow={4}
        title="pop culture"
        posts={carousel5}
        ctaPath="/category/pop-culture/"
      />
      <Carousel
        cardsToShow={4}
        title="fashion & beauty"
        end
        posts={carousel6}
        ctaPath="/category/fashion-2/"
      />
      <Carousel
        cardsToShow={4}
        title="illustration"
        posts={carousel7}
        ctaPath="/category/illustration/"
      />
      <Carousel
        cardsToShow={4}
        title="industrial design"
        end
        posts={carousel8}
        ctaPath="/category/industrial-design/"
      />
    </Layout>
  )
}

export default HomePage
