import React, { useEffect, useState } from "react"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import { LatestPostsData } from "../../hooks/latestPosts"

import {
  Container,
  Title,
  NavText,
  Subtitle,
  Navigator,
  Text,
  Img,
  TextFadeout,
  Tag,
  LinkBlock,
  InnerContainer,
} from "./styles"
import { Link } from "gatsby"

export default function Latest({ pinnedEditorial }) {
  const { editorial, position } = pinnedEditorial

  const [categories, setCategories] = useState([])
  const featuredPost = LatestPostsData()

  const thirdTeaser =
    position === "3" && editorial
      ? // display editorial
        editorial
      : (position === "1" || position === "2") && editorial
      ? // push down 1
        featuredPost.posts.nodes[1]
      : featuredPost.posts.nodes[2]

  const propsTest = {
    bigTag: thirdTeaser?.tagWord?.tagWord || null,
  }

  useEffect(() => {
    function Categories() {
      const dataCategories = thirdTeaser?.categories?.nodes
      const newCategories = dataCategories?.map((category, index) => (
        <Link
          key={index}
          to={`/category/${category.slug}/`}
          dangerouslySetInnerHTML={{
            __html: category.name,
          }}
        />
      ))
      setCategories(newCategories)
    }
    Categories()
  }, [thirdTeaser])

  function checkTag() {
    const hasTag = !propsTest.bigTag ? false : true
    return hasTag
  }

  return (
    <Container withTag={checkTag()}>
      <Title>{position === "3" && editorial ? "Featured" : "The latest"}</Title>
      <InnerContainer>
        <NavText>
          <Navigator>{categories}</Navigator>

          <Subtitle
            className="title"
            dangerouslySetInnerHTML={{
              __html: thirdTeaser.title,
            }}
          ></Subtitle>
        </NavText>
        <Img>
          {thirdTeaser?.featuredImage && (
            <ResponsiveGatsbyImage
              src={thirdTeaser.featuredImage.sourceUrl}
              srcSet={thirdTeaser.featuredImage.srcSet}
              sizes="(min-width: 1024px) 900px, 100vw"
              aspectRatio={1.42}
              height={thirdTeaser.featuredImage.mediaDetails.height}
              width={thirdTeaser.featuredImage.mediaDetails.width}
            />
          )}
        </Img>
        <TextFadeout>
          <LinkBlock to={thirdTeaser.link}>
            <Text dangerouslySetInnerHTML={{ __html: thirdTeaser.excerpt }} />
          </LinkBlock>
          {/* <Fadeout /> */}
        </TextFadeout>
      </InnerContainer>
      <Tag withTag={checkTag()}>{propsTest.bigTag}</Tag>
    </Container>
  )
}
