import { useStaticQuery, graphql } from "gatsby"

export const PinnedEditorialData = () => {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query pinnedEditorialConfig {
        wpgraphql {
          justins(where: { id: 101942 }) {
            nodes {
              title
              avSiteOptions {
                avPinnedEditorial {
                  editorial {
                    ... on WPGraphQL_Editorial {
                      title
                      colourFeatureImage
                      content
                      link
                      date
                      excerpt
                      featuredImage {
                        sourceUrl
                        srcSet
                        mediaDetails {
                          width
                          height
                        }
                      }
                      categories(first: 3) {
                        nodes {
                          name
                          slug
                        }
                      }
                    }
                  }
                  position
                }
              }
            }
          }
        }
      }
    `
  )

  return wpgraphql?.justins?.nodes[0]?.avSiteOptions?.avPinnedEditorial
}
