import styled from "styled-components"
import { Link } from "gatsby"

import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  padding: 0 20px;
  margin: 40px auto;
  position: relative;
  min-height: 540px;

  @media ${device.tablet} {
    padding: 0 40px;
    max-width: 1210px;
    margin: 78px auto;
  }
  @media ${device.desktop} {
    margin-top: 110px;
    margin-bottom: ${props => (props.withTag ? "225px" : "100px")};
    height: 60vw;
    max-height: 742px;
  }
  @media ${device.large} {
    padding: 0;
    height: 52vw;
    margin-bottom: ${props => (props.withTag ? "218px" : "100px")};
  }

  a {
    text-decoration: none;
    pointer-events: all;
    /* ::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
    } */
  }

  :hover {
    .title {
      color: ${props => props.theme.colors.teal};
      transition: 90ms;
    }

    .gatsby-image-wrapper {
      transform: scale(1.04);
    }
  }
`

export const LinkBlock = styled(Link)`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`

export const InnerContainer = styled.div``
export const Title = styled.h1`
  font-size: 64px;
  color: ${props => props.theme.colors.grey[4]};
  letter-spacing: -1.67px;
  line-height: 42px;
  background: #fff;
  z-index: 12;
  margin-bottom: 33px;

  @media ${device.tablet} {
    font-size: 72px;
    letter-spacing: -1.88px;
    margin-bottom: 58px;
  }
  @media ${device.desktop} {
    font-size: 84px;
    letter-spacing: -2.19px;
    margin-bottom: 0px;
    padding-bottom: 73px;
    width: 490px;
  }
  @media ${device.large} {
    font-size: 96px;
    letter-spacing: -2.5px;
    padding-bottom: 84px;
  }
`
export const NavText = styled.div`
  width: 100%;
  padding-right: 8px;
  background: #fff;
  margin-bottom: 20px;
  pointer-events: none;
  z-index: 5;
  position: relative;

  @media ${device.tablet} {
    width: 390px;
    padding-right: 30px;
    position: absolute;
  }
  @media ${device.desktop} {
    position: relative;
    width: 490px;
    padding-right: 68px;
    margin-bottom: 0;
    padding-bottom: 40px;
  }
`
export const Navigator = styled.nav`
  pointer-events: none;

  a {
    pointer-events: all;

    text-transform: uppercase;
    font-weight: 600;
    font-size: 8px;
    color: ${props => props.theme.colors.grey[2]};
    letter-spacing: 0.33px;
    line-height: 100%;
    text-decoration: none;
    padding-right: 12px;

    :hover {
      color: ${props => props.theme.colors.teal};
      transition: 90ms;
    }

    @media ${device.tablet} {
      font-size: 10px;
      letter-spacing: 0.42px;
      padding-right: 15px;
    }
    @media ${device.desktop} {
      padding-right: 20px;
    }
  }

  @media ${device.tablet} {
    background: #fff;
    font-size: 10px;
    letter-spacing: 0.42px;
    padding-right: 15px;
  }
  @media ${device.desktop} {
    padding-right: 20px;
  }
`
export const Subtitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.theme.colors.black};
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 10px;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 36px;
    margin-top: 15px;
    margin-bottom: 26px;
  }

  @media ${device.desktop} {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  @media ${device.large} {
    font-size: 36px;
    line-height: 42px;
  }
`
export const Text = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.grey[3]};
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
  overflow: hidden;
`
export const Img = styled.div`
  margin-top: 0;
  overflow: hidden;

  .gatsby-image-wrapper {
    transform: scale(1);
    transition: 400ms;
    z-index: -1;

    ::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.05;
      content: "";
      z-index: 123;
      background: black;
    }
  }
  @media ${device.tablet} {
    margin-left: calc(20% - 20px);
    position: static;

    .gatsby-image-wrapper {
      margin-bottom: 0;
    }
  }
  @media ${device.desktop} {
    margin-left: calc(25% + 20px);
    position: absolute;
    width: calc(75% - 100px);
    top: 115px;
    z-index: -2;

    .gatsby-image-wrapper {
      width: 100%;
      padding-left: 15px;
    }
  }

  @media ${device.large} {
    .gatsby-image-wrapper {
      padding-left: 15px;
    }
    width: calc(75% - 20px);
  }
`
export const TextFadeout = styled.div`
  padding-top: 25px;

  @media ${device.tablet} {
    margin-left: calc(20% - 20px);
    position: static;
  }
  @media ${device.desktop} {
    margin-left: 0;
    display: block;
    width: 32%;
    padding-top: 0;
    padding-right: 7%;
  }
  @media ${device.large} {
    width: 25%;
    padding-right: 3%;
  }
`
export const Tag = styled.h5`
  display: none;
  @media ${device.desktop} {
    display: ${props => (props.withTag ? "block" : "none")};
    font-size: 220px;
    color: #f0f4f4;
    letter-spacing: 0;
    line-height: 220px;
    position: absolute;
    z-index: -3;
    margin-bottom: 0;
    bottom: 0%;
    transform: translateY(50%);
  }

  @media ${device.large} {
    font-size: 250px;
    line-height: 250px;
    bottom: 0;
  }
`

export const Fadeout = styled.div`
  position: relative;
  bottom: 4em;
  height: 4em;

  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`
