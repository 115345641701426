import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const Number = styled.span`
	font-size: 14px;
	margin-bottom: ${(props) => (props.variation ? '18px' : '10px')};

	@media ${device.desktop} {
		font-size: 16px;
		margin-bottom: ${(props) => (props.variation ? '38px' : '20px')};
	}
	a {
		font-weight: 600;
		color: ${(props) => props.theme.colors.black};
		text-decoration: none;
		position: relative;

		:hover {
			::before {
				opacity: 1;
				bottom: -2px;
			}
		}
		::before {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			opacity: 0;
			transition: 100ms;
			height: 2px;
			width: 100%;
			background: ${(props) => props.theme.colors.turquoise};
		}
	}
`;

export const ResultsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ResultsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 15px;
	grid-column-gap: ${(props) => props.theme.mobileGap};
	margin-bottom: 30px;

	@media ${device.tablet} {
		grid-template-columns: 1fr 1fr 1fr;
		grid-row-gap: 20px;

		grid-template-rows: auto auto;
	}

	@media ${device.desktop} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: ${(props) => props.theme.desktopGap};
		grid-row-gap: 25px;
	}

	@media ${device.large} {
		grid-column-gap: ${(props) => props.theme.largeGap};
		grid-row-gap: 30px;
		margin-bottom: ${(props) => (props.variation ? '50px' : '30px')};
	}
`;
