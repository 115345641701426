import React, { useRef, useState, useEffect, useCallback } from 'react';
import algoliasearch from 'algoliasearch';
import Categories from './Categories';
import { navigate, useStaticQuery, graphql, Link } from 'gatsby';

import {
	Container,
	WrapperHeader,
	Title,
	Header,
	Search,
	Input,
	ArtworkAuthor,
	ResultsWrapper,
	CloseSearch
} from './browseCategoriesStyles';
import { ReactComponent as SearchRemove } from '../../images/search-remove.svg';
import { ReactComponent as SearchIcon } from '../../images/search-icon.svg';
import { SearchResults } from '../SearchResults';
import Img from 'gatsby-image';


const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
const algoliaIndex = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);

export default function Index(props) {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "bg-search.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 3000, quality: 95) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const inputRef = useRef();

	const [ query, setQuery ] = useState('');
	const [ queryExists, setQueryExists ] = useState(false);
	const [ posts, setPosts ] = useState([]);
	const [ totalPosts, setTotalPosts ] = useState([]);
	const [ noResults, setNoResults ] = useState(false);

	/**** ALGOLIA DEV ****/

	const findResults = useCallback(
		() => {
			console.log('hi');
			algoliaIndex.search(
				{
					query: query
				},
				(err, data = {}) => {
					if (err) throw err;
					setPosts(data.hits);
					setTotalPosts(data.nbHits);
				}
			);
		},
		[ query ]
	);

	const checkEnter = (e) => {
		if (e.key === 'Enter' && !noResults) {
			navigate(`/search/?q=${query}`);
		}
	};

	const escHandler = (event) => {
		if (event.keyCode === 27) {
			setQuery('');
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', escHandler);

		return function cleanup() {
			window.removeEventListener('keydown', escHandler);
		};
	}, []);

	useEffect(
		() => {
			query.length ? setQueryExists(true) : setQueryExists(false);
			queryExists && findResults();
		},
		[ query, queryExists, findResults ]
	);

	useEffect(
		() => {
			if (totalPosts === 0) {
				setNoResults(true);
			} else {
				setNoResults(false);
			}
		},
		[ totalPosts ]
	);
	return (
		<Container>
			<WrapperHeader>
				<Header>
					<Title>What inspires you?</Title>
					<Search>
						<SearchIcon />
						<Input
							spellcheck={false}
							placeholder="Search for inspiration..."
							type="text"
							ref={inputRef}
							onChange={(e) => setQuery(e.target.value)}
							value={query}
							data-hj-whitelist
							onKeyPress={checkEnter.bind(this)}
						/>
						<CloseSearch queryExists={queryExists} onClick={() => setQuery('')}>
							<SearchRemove className="search-remove" />
						</CloseSearch>
					</Search>

					<ResultsWrapper queryExists={queryExists}>
						<SearchResults
							noResults={noResults}
							query={query}
							posts={posts}
							totalPosts={totalPosts}
							queryExists={queryExists}
							variation
						/>
					</ResultsWrapper>
				</Header>
				<Img className="bg-image" fluid={data.file.childImageSharp.fluid} />

				<ArtworkAuthor>
					Artwork: <Link to="/lost-in-color-minimalist-illustrations-by-effy-zhang/">Effy Zhang</Link>
				</ArtworkAuthor>
			</WrapperHeader>
			{/* <Categories title={props.title} browser /> */}
		</Container>
	);
}
