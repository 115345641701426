import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';

export const Container = styled.div`
	margin: 40px auto;
	width: ${(props) => `calc(100% - 2 *${props.theme.mobileGutter})`};
	max-width: ${(props) => props.theme.mainMaxWidth};

	@media ${device.tablet} {
		margin: 70px auto;

		width: ${(props) => `calc(100% - 2 *${props.theme.desktopGutter})`};
	}

	@media ${device.desktop} {
		width: ${(props) => `calc(100% - 2 *${props.theme.desktopGutter})`};
		margin: 90px auto;
	}

	> div {
		width: 100% !important;
	}
`;
