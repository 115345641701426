import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const CommunityContainer = styled.div`
	margin: 40px auto;
	margin-bottom:20px;
	width: ${(props) => `calc(100% - 2 *${props.theme.mobileGutter})`};
	max-width: ${(props) => props.theme.mainMaxWidth};

	@media ${device.tablet} {
		
		margin: 70px auto 10px auto;
		width: ${(props) => `calc(100% - 2 *${props.theme.desktopGutter})`};
	}

	@media ${device.desktop} {
		margin: 85px auto 50px auto;
		width: ${(props) => `calc(100% - 2 *${props.theme.desktopGutter})`};
	}
`;

export const Title = styled.h2`
	color: ${(props) => props.theme.colors.grey[1]};
	font-size: 64px;
	line-height: 75%;
	margin-bottom: 40px;

	@media ${device.tablet} {
		font-size: 72px;
	}

	@media ${device.desktop} {
		font-size: 84px;
	}

	@media ${device.large} {
		font-size: 96px;
	}
`;

export const CommunityGrid = styled.div`
	display: grid;
	margin-top: 16px;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	@media ${device.tablet} {
		margin-top: 12px;
		grid-template-columns: 1fr 1fr;
		grid-gap: ${(props) => props.theme.mobileGap};
	}

	@media ${device.desktop} {
		grid-gap: ${(props) => props.theme.desktopGap};
	}

	@media ${device.large} {
		grid-gap: ${(props) => props.theme.largeGap};
		margin-top: 32px;

	}
`;

export const PrivacyText = styled.p`
margin-top:16px;
font-size: 11px;
color:#4A5050;
width: 100%;

@media ${device.tablet} {
margin-top: 17px;
font-size: 13px;
/* width: 55%; */

}


@media ${device.large} {
	margin-top: ${props => props.article ? '40px' : '27px'};
width: ${props => props.article ? '574px' : null};
font-size: 14px;

}

@media ${device.big} {
width: 70%;
width: ${props => props.article ? '574px' : null};

grid-column: 2 / span 1;
}

a {
	color: #19191A;
	font-weight:600;
}
	
	
`;