import React from 'react'
import { ReactComponent as Art } from "../images/icons/art.svg"
import { ReactComponent as Architecture } from "../images/icons/architecture.svg"
import { ReactComponent as GraphicDesign } from "../images/icons/graphic_design.svg"
import { ReactComponent as Illustration } from "../images/icons/illustration.svg"
import { ReactComponent as Photography } from "../images/icons/photography.svg"
import { ReactComponent as Typography } from "../images/icons/typography.svg"
import { ReactComponent as Branding } from "../images/icons/branding.svg"
import { ReactComponent as Fashion } from "../images/icons/fashion.svg"
import { ReactComponent as IndustrialDesign } from "../images/icons/industrial_design.svg"
import { ReactComponent as InteriorDesign } from "../images/icons/interior_design.svg"
import { ReactComponent as LogoDesign } from "../images/icons/logo_design.svg"
import { ReactComponent as Packging } from "../images/icons/packging.svg"
import { ReactComponent as PopCulture } from "../images/icons/pop_culture.svg"
import { ReactComponent as PrintDesign } from "../images/icons/print_design.svg"
import { ReactComponent as Technology } from "../images/icons/technology.svg"
import { ReactComponent as UiDesign } from "../images/icons/ui_design.svg"
import { ReactComponent as VehicleDesign } from "../images/icons/vehicle_design.svg"
import { ReactComponent as Video } from "../images/icons/video.svg"


const categoryMap = {
'advertising' : {icon: Art },
'deals' : {icon: Art },
'product-design': {icon: Art },
'art': {icon: Art },
'architecture': {icon: Architecture },
'design': {icon: GraphicDesign },
'illustration': {icon: Illustration },
'photography': {icon: Photography },
'typography': {icon: Typography },
'identity': {icon: Branding },
'fashion-2': {icon: Fashion },
'industrial-design': {icon: IndustrialDesign },
'interiordesign': {icon: InteriorDesign },
'logos': {icon: LogoDesign },
'packaging': {icon: Packging },
'pop-culture': {icon: PopCulture },
'print': {icon: PrintDesign },
'technology-2': {icon: Technology },
'ui-design': {icon: UiDesign },
'automotive-2': {icon: VehicleDesign },
'video': {icon: Video },
}

export const hasIcon = (category) => {
	return !!categoryMap[category];
};

export const GetCategoryIcon = ({ category }) => {
	return categoryMap[category] ? React.createElement(categoryMap[category].icon) : false;
};