import React from "react"
import { ResponsiveGatsbyImage } from "../ResponsiveGatsbyImage"
import {
  Container,
  InnerContainer,
  Img,
  Category,
  CategoryLink,
  TitleLink,
  Date,
  Description,
  KeepLink,
} from "./editorialBigTeaserStyles"
import moment from "moment"

export default function EditoriaBigTeaser({
  categories,
  title,
  date,
  excerpt,
  link,
  featuredImage,
  imageReverse,
  colourFeatureImage,
}) {
  // console.log(featuredImage)
  return (
    <Container imageReverse={imageReverse} className="bigTeaser">
      <Img>
        <ResponsiveGatsbyImage
          src={featuredImage.sourceUrl}
          srcSet={featuredImage.srcSet}
          sizes="(min-width: 768px) 33vw, 50vw"
          aspectRatio={1.42}
          // height={
          //   !featuredImage.mediaDetails.height
          //     ? "988px"
          //     : featuredImage.mediaDetails.height
          // }
          // width={
          //   !featuredImage.mediaDetails.width
          //     ? "988px"
          //     : featuredImage.mediaDetails.height
          // }
          style={{
            backgroundColor: colourFeatureImage
              ? `rgb(${colourFeatureImage})`
              : null,
          }}
        />
      </Img>

      <InnerContainer imageReverse={imageReverse}>
        {!!categories?.length && (
          <Category>
            {categories.slice(0, 3).map((category, index) => (
              <CategoryLink
                to={`/category/${category.slug}/`}
                key={index}
                dangerouslySetInnerHTML={{ __html: category.name }}
              />
            ))}
          </Category>
        )}
        <TitleLink
          className="title"
          to={link}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Date>{moment(date).format("ll")}</Date>
        <Description dangerouslySetInnerHTML={{ __html: excerpt }} />
        <KeepLink className="title" to={link}>
          Keep reading
        </KeepLink>
      </InnerContainer>
    </Container>
  )
}
