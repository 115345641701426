import React from 'react';
import { Button } from '../Button';
import { Teaser } from '../Teaser';
import { Link } from 'gatsby';

import { ResultsContainer, ResultsGrid, Number } from './style.js';

const SearchResults = ({ query, posts, totalPosts, noResults, queryExists, variation }) => {

	return (
		<ResultsContainer>
			<Number variation={variation}>
				{queryExists ? (
					<span>
						{noResults ? ' No' : totalPosts} result{totalPosts === 1 ? null : 's'} found. {noResults ? 'Try another query.' : <Link  to={`/search/?q=${query}`}>View all</Link>} 
					</span>
				) : (
					'Type anything and hit enter to find some inspiration.'
			)}
			</Number>
			{queryExists && !noResults ?  (
				<>
				<ResultsGrid variation={variation}>
				{posts
					.slice(0,8)
					.map((post, index) => (
						<Teaser
							small
							key={index}
							title={post.title}
							slug={post.slug}
							img={post.image}
							/>
					))}
				
				</ResultsGrid>
				{noResults ? null :	<Button full label="View all results" path={`/search/?q=${query}`} />}
				</>

			) : null}
		</ResultsContainer>
	);
};

export default SearchResults;
