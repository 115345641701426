import { useStaticQuery, graphql } from 'gatsby';


export const PopCulture = () => {
	const { wpgraphql } = useStaticQuery(
		graphql`
			query popCulturePosts {
				wpgraphql {
					posts(first: 12, where: { categoryName: "pop-culture" }) {
						nodes {
							title
							link
							colourFeatureImage
							featuredImage {
								sourceUrl
								srcSet
								mediaDetails {
									width
									height
								}
							}
						}
					}
				}
			}
		`
	);

	return wpgraphql.posts.nodes;
};
