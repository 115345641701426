import React from 'react';
import { GetCategoryIcon } from '../../utils/categories';

import { CategoriesData } from '../../hooks/categories-data.js';

import { Container, Title, Icons, IconAndName, IconName } from './categoriesStyles';


function Categories({ browser, category, title, related }) {
	const categories = related && related.length > 0 ? related : CategoriesData();

	return (
		<Container category={category} browser={browser} id="relatedCats">
			<Title category={category} browser={browser}>{title}</Title>
			<Icons category={category} browser={browser}>
				{categories.map((cat, index) => (
					<IconAndName browser={browser ? 1 : 0} to={`/category/${cat.slug}/`} category={category ? 1 : 0} key={index}>
						<GetCategoryIcon category={cat.slug} key={index} />
            <IconName browser={browser} category={category} dangerouslySetInnerHTML={{ __html: cat.name }}/>
					</IconAndName>
				))}
			</Icons>
		</Container>
	);
}
export default Categories;
