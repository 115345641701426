import styled from 'styled-components';
import { device } from '../../utils/breakpoints';
import { Link } from 'gatsby';

export const Container = styled.div`
	margin: 0px auto;
	max-width: ${(props) => props.theme.mainMaxWidth};
	position: relative;
	border-bottom: 1px solid #d7e1e2;
	padding-bottom: 20px;
  margin-top:20px;
	width: ${(props) => `calc(100% - 2*${props.theme.mobileGutter})`};

	:hover {
		.gatsby-image-wrapper {
			transform: scale(1.12);
		}

		.title {
			color: ${(props) => props.theme.colors.teal};
		}
	}

	@media ${device.tablet} {
		width: ${(props) => `calc(100% - 2*${props.theme.desktopGutter})`};
		padding-bottom: 40px;

	}
	@media ${device.desktop} {

		display: flex;
		flex-direction: ${(props) => (props.imageReverse ? 'row-reverse' : 'row')};
		justify-content: space-between;
	}
	@media ${device.large} {
		padding-bottom: 60px;

	}
`;
export const InnerContainer = styled.div`
	@media ${device.desktop} {
		width: 50%;
		padding-left: ${(props) => (props.imageReverse ? '0' : '55px')};
		padding-right: ${(props) => (props.imageReverse ? '55px' : '0')};
	}
`;
export const Img = styled.div`
	height: 100%;
	display: block;
	cursor: pointer;
	overflow: hidden;
	.gatsby-image-wrapper {
		transition: 400ms;
	}

	img {
		margin-bottom: 0;
	}

	@media ${device.desktop} {
		max-width: 574px;
		width: 50%;
		.gatsby-image-wrapper {
			height: 404px;
		}
	}
`;
export const Category = styled.div`
	display: flex;
	margin-top: 0;
`;
export const CategoryLink = styled(Link)`
  font-family: "AvenirNext";
  font-weight: 700;
  font-size: 8px;
  color: ${(props) => props.theme.colors.teal};
  letter-spacing: 0.33px;
  line-height: 100%;
  text-transform: uppercase;
  padding-right: 15px;
  margin-bottom: 10px;
  z-index:2;
  margin-top: 15px;
  text-decoration: none;
  cursor: pointer;

  :hover {
    opacity: 0.7;
    transition:50ms;
  }

  @media ${device.desktop} {
    margin-top: 0;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 12px;
  }
`;
export const TitleLink = styled(Link)`
  font-family: NeuzeitGro;
  font-weight: 600;
  font-size: 28px;
  color: #19191a;
  letter-spacing: -0.39px;
  line-height: 32px;
  text-decoration: none;
  margin-bottom: 0;

  ::after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    cursor: pointer;

    height:100%;
  }


  @media ${device.desktop} {
    font-size: 48px;
    letter-spacing: -1px;
    line-height: 48px;
  }
`;

export const Date = styled.h6`
	font-family: "AvenirNext";
	font-size: 12px;
	color: #9cacae;
	letter-spacing: 0;
	line-height: 42px;
	margin-top: 0;
	margin-bottom: 0;

	@media ${device.desktop} {
		margin-top: 15px;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 30px;
	}
`;
export const Description = styled.p`
	font-family: "AvenirNext";
	font-size: 16px;
	color: #4a5050;
	letter-spacing: 0;
	line-height: 24px;
	margin-top: 0;

	@media ${device.desktop} {
		margin-top: 20px;
		font-size: 20px;
		letter-spacing: 0;
		line-height: 30px;
	}
`;

export const KeepLink = styled(Link)`
  font-family: "AvenirNext";
  font-weight: 700;
  font-size: 16px;
  color: #19191a;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
  margin-top: 23px;

  :after {
    content: "";
    position: relative;
    left: -100px;
    bottom: -6px;
    display: inline-block;
    height: 2px;
    width: 100px;
    background: ${(props) => props.theme.colors.turquoise};
  }
  :hover {
    color: ${(props) => props.theme.colors.teal};
  }

  @media ${device.desktop} {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    :after {
      content: "";
      left: -125px;
      bottom: -6px;
      height: 2px;
      width: 125px;
      background: ${(props) => props.theme.colors.turquoise};
    }
  }
`;
