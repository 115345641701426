import React from "react"
import { Container } from "./style.js"
import { Title } from "../FromTheCommunity/style.js"
import EditoriaBigTeaser from "../Teaser/editorialBigTeaser"
import { Teaser } from "../Teaser"
import { EditorialSmallGrid } from "../TeaserGrids/index.js"
import { AdvertisementWrapper } from "../AdvertisementWrapper/index.js"
import { GoogleAd } from "../GoogleAd/index.js"
import { Button } from "../Button/index.js"
import { LatestEditorialsData } from "../../hooks/latestEditorials"

const EditorialHome = props => {
  const latestEditorials = LatestEditorialsData()
  const firstEditorial = latestEditorials.posts[0]
  const secondaryEditorials = latestEditorials.posts.slice(1, 7)

  return (
    <Container>
      <Title>News & Resources</Title>
      <EditoriaBigTeaser
        title={firstEditorial.title}
        date={firstEditorial.date}
        excerpt={firstEditorial.excerpt}
        featuredImage={firstEditorial.featuredImage}
        link={firstEditorial.link}
        categories={firstEditorial.categories.nodes}
      />
      <EditorialSmallGrid>
        {secondaryEditorials.map((teaser, index) => {
          return (
            <Teaser
              key={index}
              little
              title={teaser.title}
              img={teaser.featuredImage}
              link={teaser.link}
              categories={teaser.categories.nodes}
            />
          )
        })}
        <AdvertisementWrapper editorialSquare>
          <GoogleAd
            className="square"
            client="ca-pub-4461681428787239"
            slots={["5437032467"]}
          />{" "}
        </AdvertisementWrapper>
      </EditorialSmallGrid>
      <Button full path="/editorial/" label="View all articles" />
    </Container>
  )
}

export default EditorialHome
