import styled from 'styled-components';
import { Link } from 'gatsby';

import { device } from '../../utils/breakpoints';

export const Container = styled.div`
	padding: ${(props) => (props.category ? '0 20px' : '0 20px')};
	margin: 20px auto;

	@media ${device.tablet} {
		max-width: 1200px;
		width: calc(100% - 40px);
		margin: 30px auto;
		margin-top: 80px;
		display: ${(props) => (props.category ? 'flex' : 'block')};
		flex-direction: ${(props) => (props.category ? 'row' : 'none')};
		justify-content: ${(props) => (props.category ? 'flex-start' : 'none')};
		align-items: ${(props) => (props.category ? 'center' : 'none')};
  }
  
	@media ${device.large} {
    padding: ${(props) => (props.category ? '0' : '0 20px')};

  }

`;
export const Title = styled.h2`
	font-size: ${(props) => (props.category ? '11px' : '28px')};
	font-weight: ${(props) => (props.category ? '700' : '600')};
	color: ${(props) => (props.category ? '#4A5050' : '#D7E1E2')};
	letter-spacing: ${(props) => (props.category ? '0' : '-0.73px')};
	text-align: ${(props) => (props.category ? 'left' : 'center')};
	line-height: 32px;
	flex-shrink: 0;
	margin-bottom: ${(props) => (props.category ? '10px' : '30px')};

	@media ${device.tablet} {
		font-size: ${(props) => (props.category ? '12px' : '36px')};
		letter-spacing: ${(props) => (props.category ? '0' : '-0.94px')};
		line-height: ${(props) => (props.category ? '12px' : '72px')};
		margin-bottom: ${(props) => (props.category ? '0' : '20px')};
		margin-right: ${(props) => (props.category ? '35px' : '0')};
	}

	@media ${device.desktop} {
		font-size: ${(props) => (props.category ? '12px' : '42px')};
		letter-spacing: ${(props) => (props.category ? '0' : '-1.09px')};
		margin-bottom: ${(props) => (props.category ? '0' : '50px')};
	}
`;

export const Icons = styled.div`
	display: ${(props) => (props.category ? 'flex' : 'grid')};
	overflow: ${(props) => props.category && 'auto'};
	white-space: nowrap;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 18px;
	grid-row-gap: 15px;
	align-items: center;
	justify-items: center;
	align-content: space-around;
	padding-right: ${(props) => (props.category ? '20px' : '0')};
	/* padding: 20px 0; */

	::-webkit-scrollbar {
		width: 10px !important;
		height: 10px !important;
		display: none !important;
	}

	@media ${device.tablet} {
		grid-template-columns: repeat(4, 1fr);
		grid-row-gap: 35px;
	}
	@media ${device.desktop} {
		grid-template-columns: repeat(5, 1fr);
	}
`;
export const IconAndName = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: ${(props) => (props.category ? 'row' : 'column')};
  justify-content: ${(props) => (props.category ? 'flex-start' : 'center')};
  align-items: center;
  width: ${(props) => (props.category ? 'auto' : '100%')};
  max-width: 140px;
  height: ${(props) => (props.category ? '100%' : '80px')};
  margin-right: ${(props) => (props.category ? '20px' : '0')};
  transition: 100ms;

  @media ${device.tablet} {
    width: ${(props) => (props.category ? '50%' : '140px')};
    height: ${(props) => (props.category ? '50%' : '120px')};
    margin-top: 0;
    margin-right: ${(props) => (props.category ? '35px' : '0')};
  }

  @media ${device.desktop} {
    max-width: 100%;

    width: ${(props) => (props.category ? '50%' : '156px')};
  }

  :hover {
    -webkit-box-shadow: ${(props) => (props.category ? 'none' : '  1px 1px 10px 0 rgba(0,0,0,0.15)')};
    -moz-box-shadow: ${(props) => (props.category ? 'none' : '  1px 1px 10px 0 rgba(0,0,0,0.15)')};
    box-shadow: ${(props) => (props.category ? 'none' : '  1px 1px 10px 0 rgba(0,0,0,0.15)')};
    border-radius: 2px;
    cursor: pointer;
    h6 {
      color: ${(props) => props.theme.colors.black};
    }
    svg g,
    path {
      fill: ${(props) => props.theme.colors.turquoise};
    }
  }

  svg {
    width: ${(props) => (props.category ? '18px' : 'auto')};
    height: ${(props) => (props.browser ? '32px' : null)};
    transition: 100ms;
    flex-shrink: 0;
  }
`;
export const IconName = styled.h6`
	font-weight: ${(props) => (props.category ? '600' : '600')};
	font-size: ${(props) => (props.category ? '14px' : '11px')};
	color: #9cacae;
	letter-spacing: 0;
	text-align: center;
	line-height: 11px;
	margin-top: ${(props) => (props.category ? '0' : '15px')};
	margin-left: ${(props) => (props.category ? '10px' : '0')};
	margin-bottom: 0;
	font-family: ${(props) => props.theme.primaryFont};
	transition: 100ms;

	::first-letter {
		text-transform: uppercase;
	}

	@media ${device.tablet} {
		font-size: 15px;
		line-height: 24px;
	}

	@media ${device.desktop} {
		font-size: 16px;
	}
`;
