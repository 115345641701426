import React from 'react';
import { CommunityContainer, Title, CommunityGrid, PrivacyText } from './style.js';
import { Teaser } from '../Teaser';
import { SignUp } from '../SignUp';
import { useStaticQuery, graphql, Link } from 'gatsby';

const FromTheCommunity = () => {
	const { wpgraphql } = useStaticQuery(graphql`
		query CommunityPosts {
			wpgraphql {
				communityPosts {
					nodes {
						communityPost {
							title
							name
							link
							image {
								sourceUrl
								srcSet
								mediaDetails {
									width
									height
								}
							}
						}
					}
				}
			}
		}
	`);

	const communityPosts = wpgraphql.communityPosts.nodes;

	return (
		<CommunityContainer>
			<Title>From the community</Title>
			<SignUp />
			<PrivacyText>We care about protecting your data. Please refer to our <Link to="/privacy-policy-terms-of-use/">Privacy Policy</Link> for more.</PrivacyText>

			<CommunityGrid>
				{communityPosts.slice(0, 2).map((post, index) => {
					return <Teaser key={index} community title={post.communityPost.title} artist={post.communityPost.name} img={post.communityPost.image} link={post.communityPost.link}/>;
				})}
			</CommunityGrid>
		</CommunityContainer>
	);
};

export default FromTheCommunity;
